<header class="container mx-auto flex">
  <a href="/">
    <img src="/assets/logo.png" alt="Weblauncher" class="logo h-50" />
  </a>
  <ul class="grow flex justify-end">
    <li class="nav-item">
      <a routerLink="/home" routerLinkActive="active" class="nav-link">Home</a>
    </li>
    <li class="nav-item">
      <a routerLink="/about" routerLinkActive="active" class="nav-link"
        >About</a
      >
    </li>
    <li class="nav-item">
      <a routerLink="/services" routerLinkActive="active" class="nav-link"
        >Services</a
      >
    </li>
    <li class="nav-item">
      <a routerLink="/clients" routerLinkActive="active" class="nav-link"
        >Clients</a
      >
    </li>
    <li class="nav-item">
      <a routerLink="/join" routerLinkActive="active" class="nav-link"
        >Join us</a
      >
    </li>
  </ul>
</header>
<main class="container mx-auto mt-4">
  <router-outlet></router-outlet>
</main>
<footer class="mt-8">Copyright © 2024 WebLauncher - All Rights Reserved</footer>
